import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo/seo';
import { Container, Stack, Row, Col } from "react-bootstrap";
import './styles/index.scss';

const investors = ({ data }) => {
  console.log(data)
  return (
    <div className="background-theme">
      <Container className="pt-5 pb-5 internal-pages-common investors">
        <h1>Investors</h1>
        <Stack className="card-long">
          <img alt="card-long-first" src={data.allDatoCmsInvestorsPage.nodes[0].bannerPage.url} />
          <div className='back-image'>
            <h4>Authentic Digital Trading Cards</h4>
            <p>Creation / Minting / Marketing Custom Curated Collectibles</p>
          </div>
        </Stack>
        <Stack>
          <div className='text-title-img right'>
            <div>
              {/* <StructuredText data={data.allDatoCmsInvestorsPage.nodes[0].bodysPage[0].paragraph} /> */}
              <img alt="digitalTradingCards" src={data.allDatoCmsInvestorsPage.nodes[0].bodysPage[0].image.url} />
              <p dangerouslySetInnerHTML={{ __html: data.allDatoCmsInvestorsPage.nodes[0].bodysPage[0].paragraph }}>
              </p>
            </div>
          </div>
          <div className='text-title-img left'>
            <div>
              {/* <StructuredText data={data.allDatoCmsInvestorsPage.nodes[0].bodysPage[0].paragraph} /> */}
              <img className='me-3' alt="digitalTradingCards" src={data.allDatoCmsInvestorsPage.nodes[0].bodysPage[1].image.url} />
              <p dangerouslySetInnerHTML={{ __html: data.allDatoCmsInvestorsPage.nodes[0].bodysPage[1].paragraph }}>
              </p>
            </div>
          </div>
        </Stack>

      </Container>
      <Stack>
        <div className='blue-footer'>
          <Container>
            <Row>
              <Col className="blog-paragraph" xs={12} md={8}>
                <p dangerouslySetInnerHTML={{ __html: data.allDatoCmsInvestorsPage.nodes[0].videoSection[0].paragraph1 }}></p>
                <p dangerouslySetInnerHTML={{ __html: data.allDatoCmsInvestorsPage.nodes[0].videoSection[0].paragraph2 }}></p>
              </Col>
              <Col xs={12} md={4}>
                <iframe title='videoUrl' width="420" height="315" src={data.allDatoCmsInvestorsPage.nodes[0].videoSection[0].videoUrl}>
                </iframe>
              </Col>
            </Row>
          </Container>
        </div>
      </Stack> 
    </div>
  )
}

export default investors

export const Head = ({ data }) => (
  <Seo
    title={data.allDatoCmsInvestorsPage.nodes[0].seo.title}
    description={data.allDatoCmsInvestorsPage.nodes[0].seo.description}
    image={data.allDatoCmsInvestorsPage.nodes[0].seo.image.url}
  />
)


export const query = graphql`
query InvestorsQuery {
  allDatoCmsInvestorsPage {
    nodes {
      seo {
        title
        description
        image {
          url
        }
      }
      slugPage {
        title
        slug
      }
      bannerPage {
        url
      }
      bodysPage {
        paragraph
        image {
          url
        }
      }
      videoSection {
        paragraph1
        paragraph2
        videoUrl
      }
    }
  }
}
` 